.scrollable::-webkit-scrollbar {
    width: 0.2em;
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #888;
}

.scrollable::-webkit-scrollbar-track {
    background-color: #e1e1e1e;
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background: #555;
}
